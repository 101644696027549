import React from "react";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';



function Contents({}){
    return(
        <>
        <div  id="hero d-flex align-items-center mb-5" >
            <div className="container col-xxl-8 px-4 py-5 mb-5">
                    <div className="d-flex row flex-lg-row align-items-center g-5">
                        
                        <div className="col-md-7 col-12">
                            <Fade left>
                            <video autoPlay muted loop className='video' style={{width:'100%'}} >         
                                <source src="/video/video-workshop.mp4" type="video/mp4"/>       
                            </video>
                            </Fade>
                        </div>
                        <div className="col-md-5 col-12">
                            <Fade right>
                                <p className=" fw-600 section-tagline-title">Location</p>
                            </Fade>
                            <Fade right  delay={100}>
                                <h2 className="display-5 lh-1 my-3 title fw-600">Sewing Office.</h2>
                            </Fade>
                            <Fade right delay={200} >
                                <p className="mt-3">
                                Jl. Ketapang Utara No. 52F, RW. 7
                                Krukut, Kec. Taman Sari, Kota Jakarta Barat,
                                Indonesia
                                </p>
                            </Fade>
                            <Fade right delay={300}>
                                <p className="m-0 p-0 mb-2"><a href="https://api.whatsapp.com/send?phone=6285219597077&text=Hello%20sewing.id!" target="_blank" rel="nofollow" className="text-whatsapp">(+62) 852 1959 7077</a></p>
                                <p className="m-0 p-0 mb-2"><a href="https://api.whatsapp.com/send?phone=6281212228990&text=Hello%20sewing.id!" target="_blank" rel="nofollow" className="text-whatsapp">(+62) 812 1222 8990</a></p>
                                <p className="m-0 p-0 mb-2"><a href="https://api.whatsapp.com/send?phone=6282125107378&text=Hello%20sewing.id!" target="_blank" rel="nofollow" className="text-whatsapp">(+62) 821 2510 7378</a></p>
                               
                            </Fade>
                        </div>
                    </div>
                    <div>
                    
                </div>
            </div>
        </div>
        
        </>
        

    )
}
export default Contents;