import React from "react";
import Fade from 'react-reveal/Fade';



function Contents({}){
    return(
        <>
        <div id="hero d-flex align-items-center"  >
            <div className="container col-xxl-8 px-4 py-5">
                <div className="d-flex row flex-lg-row align-items-center g-5">
                    <div className="col-md-6 col-12">
                        <Fade up  delay={100}>
                            <h2 className="display-5 lh-1 my-3 title fw-600"> <img src="/assets/images/icon/needle.png" style={{width:'52px'}} alt="needle" loading="lazy" width={'auto'} height={'auto'} /> We Offer</h2>
                        </Fade>
                        <Fade up delay={200} >
                            <p className="my-4">
                            We offer a complete range of panicle services from upstream to downstream in multi-sense from scratch to your clothes ready to be marketed.
                            </p>
                            <p className="my-4">
                            Design Service which will be handled by our fashion team from ESMOD graduates, Pattern making, Sizing & Grading, Cut & Sew Production, Embroidery & Screen Printing
                            </p>
                        </Fade>
                    </div>

                    <div className="col-md-4 offset-md-2 col-12">
                        <Fade up delay={200} >
                        <div className="card">
                            <div className="card-body">
                                <p className="my-2">
                                Full Apparel Production (from scratch to finish).
                                </p>
                            </div>
                        </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <div className="container col-xxl-8 px-4 py-5">
                <div className="d-flex row flex-lg-row align-items-center g-5">
                    <div className="col-md-6 col-12">
                        {/* <Fade up>
                            <p className=" fw-600 section-tagline-title">The new way to be Progress</p>
                        </Fade> */}
                        <Fade up  delay={100}>
                            <h2 className="display-5 lh-1 my-3 title fw-600"><img src="/assets/images/icon/needle.png" style={{width:'52px'}} alt="needle" loading="lazy" width={'auto'} height={'auto'} />  Exception</h2>
                        </Fade>
                        <Fade up delay={200} >
                            <p className="my-4">
                            We have a customer service team that can help you in consulting your clothes so you can understand our exception
                            </p>
                            <p className="my-4">
                            Customized accessories can be requested and discussed with our Customer Service.
                            </p>
                        </Fade>
                    </div>
                    <div className="col-md-4 offset-md-2 col-12">
                        <Fade up delay={200} >
                        <div className="card">
                            <div className="card-body">
                                <p className="my-2">
                                We do not produce knitwear, denim wear and lingerie.
                                </p>
                            </div>
                        </div>
                        </Fade>
                    </div>
                </div>
            </div>
        
            <div className="container col-xxl-8 px-4 py-5">
                <div className="d-flex row flex-lg-row align-items-center g-5">
                    <div className="col-md-6 col-12">
                        {/* <Fade up>
                            <p className=" fw-600 section-tagline-title">The new way to be Progress</p>
                        </Fade> */}
                        <Fade up  delay={100}>
                            <h2 className="display-5 lh-1 my-3 title fw-600"><img src="/assets/images/icon/needle.png" style={{width:'52px'}} alt="needle" loading="lazy" width={'auto'} height={'auto'} />  Capabilities</h2>
                        </Fade>
                        <Fade up delay={200} >
                            <p className="my-4">We have the ability to meet the needs of your apparel business both large and small scale. All processes are carried out by teams who are experts in their fields.</p>
                            <p className="my-4">Our fabric and accessories options and availability are based on our suppliers inventory, so we will search the most suitable ones.</p>
                        </Fade>
                    </div>
                    <div className="col-md-4 offset-md-2 col-12">
                        <Fade up delay={200} >
                        <div className="card">
                            <div className="card-body">
                                <p className="my-2">
                                We provide customized embroidery, using manual sewing machine and computerized embroidery & We provide screen printing service
                                </p>
                            </div>
                        </div>
                        </Fade>
                    </div>
                </div>
            </div>
        
            <div className="container col-xxl-8 px-4 py-5">
                <div className="d-flex row flex-lg-row align-items-center g-5">
                    <div className="col-md-6 col-12">
                        {/* <Fade up>
                            <p className=" fw-600 section-tagline-title">The new way to be Progress</p>
                        </Fade> */}
                        <Fade up  delay={100}>
                            <h2 className="display-5 lh-1 my-3 title fw-600"><img src="/assets/images/icon/needle.png" style={{width:'52px'}} alt="needle" loading="lazy" width={'auto'} height={'auto'} />  Returns</h2>
                        </Fade>
                        <Fade up delay={200} >
                            <p className="my-4">We have a warranty on every product that we make. we have customer service that is ready to respond in serving every question and complaint raised.</p>
                            <p className="my-4">If there is any flaw caused from the production process, you can contact us and return the product to us and we will take care of it.</p>
                        </Fade>
                    </div>
                    <div className="col-md-4 offset-md-2 col-12 ">
                        <Fade up delay={200} >
                        <div className="card">
                            <div className="card-body">
                                <p className="my-2">
                                The process takes 5-14 days and will be return to you as soon as possible & If the flaw is because of garment`s defects, we can not do anything about it .
                                </p>
                            </div>
                        </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
        <div className="hero" id="hero d-flex align-items-center" style={{ padding:'100px 0px' }}>
            <div className="container col-xxl-8 px-4">
                <div className="d-flex row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-md-6 col-12">
                        <Fade up>
                        <img className="img-fluid" src="/assets/images/misc/about-2.png" alt="about" loading="lazy" width={'auto'} height={'auto'} />
                        </Fade>
                    </div>
                    <div className="col-md-6 col-12">
                        <Fade up>
                            <p className=" fw-600 section-tagline-title">The new way to be Progress</p>
                        </Fade>
                        <Fade up  delay={150}>
                            <h2 className="display-5 lh-1 my-3 title fw-600">Experience keeps us developing and contributing</h2>
                        </Fade>
                        <Fade up delay={300} >
                            <p className="mt-4">
                            In developing the fashion industry in Indonesia. We have worked with several well-known local brands in Indonesia and more than 10 local brands. Quality, Service and Speed ​​are our values.
                            </p>
                            <p>
                            Here are some of our clients' portfolios.
                            </p>
                        </Fade>
                        <Fade up delay={600}>
                            <a href="/portfolio" style={{textDecoration:'none'}}>
                            <button className="btn btn-primary d-flex align-items-center">Portfolios<img className="ms-2" style={{width:'16px'}} src="/assets/images/icon/needle-flip.png" alt="icon" loading="lazy" width={'auto'} height={'auto'} /></button>
                            </a>
                        </Fade>
                    </div>
                   
                    
                </div>
            </div>
        </div>
        
        </>
        

    )
}
export default Contents;