import React, { useState,useEffect} from "react";
import Slide from 'react-reveal/Slide';

function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('home');
  const data = [
    {
      title:'Home',
      link:'/'
    },
    {
      title:'Product',
      link:'#roadmap'
    },
    {
      title:'Contact Us',
      link:'#'
    },
  ]
  const handleBurgerClick= async ()=> {
    if(showMenu){
      setShowMenu(false);
    }else{
      setShowMenu(true);
    }
  }

  useEffect(() => {
    
     if(window){
      const patname = window.location.pathname;
      console.log(patname)
      if(patname === '/'){
        setSelectedMenu('home')
      }else if(patname.toLowerCase().startsWith('/about')){
        setSelectedMenu('about')
      }else if(patname.toLowerCase().startsWith('/services')){
        setSelectedMenu('services')
      }else if(patname.toLowerCase().startsWith('/portfolio')){
        setSelectedMenu('portfolio')
      }else if(patname.toLowerCase().startsWith('/contact')){
        setSelectedMenu('contact')
      }
     }
  }, []);

  useEffect(() => {
    console.log(selectedMenu);
 }, [selectedMenu]);




    return(
      <>
      <div style={{position:'fixed',width:'100%', background:'#fff', zIndex:1000}}>
        <div  style={{position:'relative'}}>
          <div className="container" >
            <header className="p-3 ">
                <div className="d-flex flex-wrap align-items-center justify-content-between justify-content-lg-start">
                  <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none me-lg-auto">
                    <img src={process.env.PUBLIC_URL +'/logo-light.png'} alt="mdo" width="auto" height="54"/>
                  </a>
                
                  <div className=" d-md-block d-none">
                    <a className={selectedMenu==='home' ? 'nav-menu active':'nav-menu'} href="/" onClick={()=>{setSelectedMenu('home')}}>Home</a>
                    <a className={selectedMenu==='about' ? 'nav-menu active':'nav-menu'}  href="/about" onClick={()=>{setSelectedMenu('about')}}>About Us</a>
                    <a className={selectedMenu==='services' ? 'nav-menu active':'nav-menu'}  href="/services">Services</a>
                    <a className={selectedMenu==='portfolio' ? 'nav-menu active':'nav-menu'}  href="/portfolio">Portfolio</a>
                    <a className={selectedMenu==='contact' ? 'nav-menu active':'nav-menu'}  href="/contact">How to Order</a>
                  </div>

                  <div className="d-md-none d-block">
                    <a className="text-dark decoration-none text-decoration-none" onClick={()=>{handleBurgerClick();}}><i className="fa fa-bars"></i></a>
                  </div>
                
                 
                </div>
            </header>
          </div>
          <div style={{position:'absolute',width:'100%'}} className={showMenu?'d-flex w-100':'d-none'}>
            <Slide right when={showMenu} style={{margin:'0'}} > 
                <div style={{background:'#D6C494',zIndex:'108 !important',left:0,width:'100%'}} className="text-center" className="py-2">
                  <ul style={{listStyleType:'none'}}>
                    <li className="mb-2"><a className={selectedMenu==='home' ? 'me-4 nav-menu-mobile active':'me-4 nav-menu-mobile'} href="/">Home</a></li>
                    <li className="mb-2"><a className={selectedMenu==='about' ? 'me-4 nav-menu-mobile active':'me-4 nav-menu-mobile'} href="/about">About Us</a></li>
                    <li className="mb-2"><a className={selectedMenu==='services' ? 'me-4 nav-menu-mobile active':'me-4 nav-menu-mobile'} href="/services">Services</a></li>
                    <li className="mb-2"><a className={selectedMenu==='portfolio' ? 'me-4 nav-menu-mobile active':'me-4 nav-menu-mobile'} href="/portfolio">Portfolio</a></li>
                    <li className="mb-2"><a className={selectedMenu==='contact' ? 'me-4 nav-menu-mobile active':'me-4 nav-menu-mobile'} href="/contact">How to Order</a></li>
                  </ul>
                </div>
            </Slide>
          </div>
          </div>
        </div>
      </>
    );
}
export default Header;