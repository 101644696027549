import React from "react";
import Fade from 'react-reveal/Fade';

function Hero(){
    return(
        <div className="hero section-hero-home" id="hero d-flex align-items-center">
            <div className="container col-xxl-8 px-4 py-5" >
                <div className="d-flex row flex-lg-row flex-column-reverse align-items-center g-5 py-5">
                    <div className="col-md-6 col-12">
                        <div className='text-center'/>
                            <Fade left >
                                <h1 className="display-5 lh-1 my-3 title fw-600">We engage positive communication of recipients with your brand.</h1>
                            </Fade>
                            <Fade left delay={150}>
                                <p className="lead py-3">
                                    <div className='subtitle-hero'>Build a better product for every partner in Indonesia's fashion industry by maximizing the manufacturing process efficiently from the scratch </div>
                                </p>
                            </Fade>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    )
}
export default Hero;