import React from "react";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

const section={
    title: 'OUR SERVICES',
    description: 'Let`s make something great together.'
}

const data = [
    {
        icon: 'assets/images/services/service-icon-1.jpg',
        title:'ESMOD, La Salle, Raffles Fashion Graduatee',
        description: 'Design Service which will be handled by our fashion team from ESMOD graduates'
    },
    {
        icon: 'assets/images/services/service-icon-1.jpg',
        title:'Pattern Making',
        description: 'You can consult us what pattern you will use in your apparel.'
    },
    {
        icon: 'assets/images/services/service-icon-1.jpg',
        title:'Sizing & Grading',
        description: 'We can customize the size and grading of your apparel.'
    },
    {
        icon: 'assets/images/services/service-icon-1.jpg',
        title:'Cut & Sew Production',
        description: 'We also include cut & sew production services for your apparel business.'
    }, 
    {
        icon: 'assets/images/services/service-icon-1.jpg',
        title:'Embroidery & Screen Printing',
        description: 'We maximize your needs including embroidery and screen printing.'
    },
    {
        icon: 'assets/images/services/service-icon-1.jpg',
        title:'Full Apparel Production',
        description: 'We provide full services from scratch to your apparel business is complete.'
    },

]

function OurServices(){
  return(
    <div className="service-section">
        <div className="container col-xxl-12 col-12 px-4 py-5">
            <div className="row flex-lg-row align-items-center g-5 py-5">
                <div className="col-lg-12">
                    <Bounce right>
                    <div className='text-center'/>
                        <h2 className=" fw-600 text-center text-label-title">{section.title}</h2>
                        <h3 className="display-5  text-center  lh-1 my-3 title fw-600">{section.description}</h3>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                    </div>
                    </Bounce>
                </div>
                {
                    data.map((item, idx) => {
                        return (
                        <div className="col-md-6 col-12 title-feature" key={`services-${idx}`}>
                            <Fade bottom duration={1200}>
                                <div className='d-flex align-items-center'>
                                    <div className='text-center me-5 icon-needle-service'>
                                        <div className='position-relative  icon-needle-service'>
                                            <img src={item.icon} width='auto' height='auto' className="icon-needle-service" alt={item.title} alt="service-icon" loading="lazy"/>
                                        </div>
                                    </div>
                                    <div>
                                        <h4 className="service-title">{item.title}</h4>
                                        <p className="service-description">{item.description}</p>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                        )
                    })
                }
            </div>
        </div>

        
    </div>
    
  )
}
export default OurServices;