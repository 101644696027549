import React, { useEffect } from 'react'
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FooterNew from '../components/Layout/Footer/FooterNew';
import OurServices from '../components/OurServices'
import Hero from '../components/Hero';
import Gallery from '../components/Gallery';
import Clients from '../components/Client';


function Home() {
    const page={
        title: 'Sewing - Your brand’s best partner.',
        description: 'Sewing.id is the right solution for fashion businesses on a small or large scale in terms of production.'
    }

    useEffect(() => {
        document.title = page.title;
        document.description = page.description;
    }, []);
    return (
        <>
        <Hero/>
        <OurServices/>
        <Gallery/>
        <Clients/>
        <FooterNew/>
        <ToastContainer />
        </>
    )
}

export default Home;
