import React from "react";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import PortoImages from "./PortoImages";
import { portfolios } from "../../data/Constats";


function Contents({}){
    return(
        <>
     
        <div  id="hero d-flex align-items-center"  >
            <div className="container col-xxl-8 px-4 py-5">
                <div className="d-flex row flex-lg-row align-items-center g-5">
                    <div className="col-md-6 col-12 mb-lg-5 mb-md-4 mb-2">
                        <PortoImages direction={'left'} data={portfolios[0]} id={0}/>
                    </div>

                    <div className="col-md-6 col-12 mb-lg-5 mb-md-4 mb-2">
                        <PortoImages direction={'left'} data={portfolios[1]} id={1}/>
                    </div>

                    <div className="col-md-6 col-12 mb-lg-5 mb-md-4 mb-2">
                        <PortoImages direction={'left'} data={portfolios[2]} id={2}/>
                    </div>

                    <div className="col-md-6 col-12 mb-lg-5 mb-md-4 mb-2">
                        <PortoImages direction={'left'} data={portfolios[3]} id={3}/>
                    </div>
                    <div className="col-md-6 col-12 mb-lg-5 mb-md-4 mb-2">
                        <PortoImages direction={'left'} data={portfolios[4]} id={4}/>
                    </div>
                    <div className="col-md-6 col-12 mb-lg-5 mb-md-4 mb-2">
                        <PortoImages direction={'left'} data={portfolios[5]} id={5}/>
                    </div>
                    <div className="col-md-6 col-12 mb-lg-5 mb-md-4 mb-2">
                        <PortoImages direction={'left'} data={portfolios[6]} id={6}/>
                    </div>
                    <div className="col-md-6 col-12 mb-lg-5 mb-md-4 mb-2">
                        <PortoImages direction={'left'} data={portfolios[7]} id={7}/>
                    </div>
                    <div className="col-md-6 col-12 mb-lg-5 mb-md-4 mb-2">
                        <PortoImages direction={'left'} data={portfolios[8]} id={8}/>
                    </div>
                    <div className="col-md-6 col-12 mb-lg-5 mb-md-4 mb-2">
                        <PortoImages direction={'left'} data={portfolios[9]} id={9}/>
                    </div>
                    <div className="col-md-6 col-12 mb-lg-5 mb-md-4 mb-2">
                        <PortoImages direction={'left'} data={portfolios[10]} id={10}/>
                    </div>
                    <div className="col-md-6 col-12 mb-lg-5 mb-md-4 mb-2">
                        <PortoImages direction={'left'} data={portfolios[11]} id={11}/>
                    </div>

                   
                </div>
            </div>
          
        </div>
        <div  id="hero d-flex align-items-center" style={{ padding:'100px 0px' }}>
            <div className="container col-xxl-8 px-4">
                <div className="d-flex row  align-items-center g-5">
                    <div className="col-12 ">
                        <Fade up>
                            <p className=" fw-600 section-tagline-title">Interested for collaboration?</p>
                        </Fade>
                        <Fade up  delay={150}>
                            <h2 className="display-5 lh-1 my-3 title fw-600">Let`s make something great together</h2>
                        </Fade>
                        <Fade up delay={300} >
                            <p className="mt-4">
                            We cut the supply chain process by creating new work patterns from starting to Sampling - Production - Finishing.
                            </p>
                            <p>
                            We also data to be a fashion consultant for you who are new and want to start a business in the field of fashion.
                            </p>
                        </Fade>
                        <Fade up delay={600}>
                            <a href="/contact" style={{textDecoration:'none'}}>
                            <button className="btn btn-primary d-flex align-items-center">Brief Us A Project<img className="ms-2" style={{width:'16px'}} src="assets/images/icon/needle-flip.png" alt="needle" loading="lazy" width={'auto'} height={'auto'} /></button>
                            </a>
                        </Fade>
                    </div>
                    
                </div>
            </div>
        </div>
        
        </>
        

    )
}
export default Contents;