import React ,{useState,useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';


const section={
    title: 'Portfolio',
    description: 'OUR LATEST WORKS'
}



function PortoImages({direction, data, id, titleAlign}){
    const images = data?.images;
    const [loaded, setLoaded] = useState(false)
    const navigate = useNavigate();

    const onLoad = () => {
       console.log('loaded');
    }

    
    return(
    <>
    <div className="mb-2 px-2">
        {direction==='left' ? 
            <div className="d-flex row">
                <div className="col-6 no-space">
                    <div className="img-hover-zoom">
                        <img className="img img-fluid" onLoad={onLoad(0)} src={images[0]?.src} alt={images[0]?.title} loading="lazy" width={'auto'} height={'auto'}    />
                    </div>
                </div>
                <div className="col-3 flex-column no-space">
                    <div className="img-hover-zoom">
                        <img className="img img-fluid" onLoad={onLoad(1)} src={images[1]?.src} alt={images[1]?.title} loading="lazy" width={'auto'} height={'auto'}     />
                    </div>
                    <div className="img-hover-zoom">
                        <img className="img img-fluid" onLoad={onLoad(2)} src={images[2]?.src} alt={images[2]?.title} loading="lazy" width={'auto'} height={'auto'}    />
                    </div>
                </div>
                <div className="col-3 flex-column no-space">
                    <div className="img-hover-zoom">
                    <img className="img img-fluid" onLoad={onLoad(3)} src={images[3]?.src} alt={images[3]?.title} loading="lazy" width={'auto'} height={'auto'}    />
                    </div>
                    <div className="img-hover-zoom">
                    <img className="img img-fluid" onLoad={onLoad(4)} src={images[4]?.src} alt={images[4]?.title} loading="lazy" width={'auto'} height={'auto'}   />
                    </div>
                </div>
            </div>
            :
            <div className="d-flex row flex-md-row flex-column">
                <div className="col-3 flex-column no-space">
                    <div className="img-hover-zoom">
                        <img className="img img-fluid" onLoad={onLoad(3)} src={images[3]?.src}  alt={images[3]?.title}  loading="lazy" width={'auto'} height={'auto'} />
                    </div>
                    <div className="img-hover-zoom">
                        <img className="img img-fluid" onLoad={onLoad(4)} src={images[4]?.src} alt={images[4]?.title} loading="lazy" width={'auto'} height={'auto'}  />
                    </div>
                </div>
                <div className="col-3 flex-column no-space">
                    <div className="img-hover-zoom">
                    <img className="img img-fluid" onLoad={onLoad(1)} src={images[1]?.src}  alt={images[1]?.title} loading="lazy" width={'auto'} height={'auto'}  />
                    </div>
                    <div className="img-hover-zoom">
                    <img className="img img-fluid" onLoad={onLoad(2)} src={images[2]?.src} alt={images[2]?.title} loading="lazy" width={'auto'} height={'auto'} />
                    </div>
                </div>
                <div className="col-6 no-space">
                    <div className="img-hover-zoom">
                        <img className="img img-fluid" onLoad={onLoad(3)} src={images[0]?.src} alt={images[0]?.title} loading="lazy" width={'auto'} height={'auto'}  />
                    </div>
                </div>
            </div>
        }
    </div>
    <div className={titleAlign==='right' ? 'text-end' : titleAlign=='center'? 'text-center':'text-left'}>
        <span style={{color:'#999',fontWeight:'400'}}>{data?.type}</span>
        <h2 className="link-porto-detail"
         onClick={()=>{
            navigate('/portfolio/'+id);
        }} style={{cursor:'pointer'}}
        >{data?.name}</h2>
    </div>
    </>
  )
}
export default PortoImages;