import React from "react";
import Fade from 'react-reveal/Fade';



function Contents({}){
    return(
        <>
        <div  id="hero d-flex align-items-center" >
            <div className="container col-xxl-8 px-4 py-5">
                <div className="d-flex row flex-lg-row align-items-center g-5">
                    
                    <div className="col-md-6 col-12">
                        <Fade left>
                        <img className="img-fluid" src="/assets/images/misc/about-1.png" alt="about-1" loading="lazy" width={'auto'} height={'auto'}  />
                        </Fade>
                    </div>
                    <div className="col-md-6 col-12">
                        <Fade right>
                            <p className=" fw-600 section-tagline-title">The new way to be Progress</p>
                        </Fade>
                        <Fade right  delay={100}>
                            <h2 className="display-5 lh-1 my-3 title fw-600">Let`s make something great together.</h2>
                        </Fade>
                        <Fade right delay={200} >
                            <p className="my-5">
                            We understand that your company continues to face intense competition, our Professional Team is ready to bring the latest communication strategies that will create success for your business.
                            </p>
                        </Fade>
                        <Fade right delay={600}>
                            <a href="/services" style={{textDecoration:'none'}}>
                            <button className="btn btn-primary d-flex align-items-center">Services<img className="ms-2" style={{width:'16px'}} src="/assets/images/icon/needle-flip.png" alt="about-2" loading="lazy" width={'auto'} height={'auto'}  /></button>
                            </a>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
        <div  id="hero d-flex align-items-center" style={{ width: '100vw',backgroundAttachment:'fixed', backgroundImage:'url("assets/images/background/bg-1.png")',backgroundSize:'cover',backgroundRepeat:'no-repeat'}}>
            <div className="container col-xxl-8 px-4 py-5" >
                <div className="d-flex row flex-lg-row flex-column g-5 py-5" >
                    <div className="col-12">
                        <Fade >
                            <h2 className="display-5 lh-1 my-3 h2 fw-600">The right solution for fashion businesses.</h2>
                        </Fade>
                    </div>
                    <div className="col-md-6 col-12">
                        <Fade  delay={150}>
                            <p className="py-3">Sewing.id is the right solution for fashion businesses on a small or large scale in terms of production. We cut the supply chain process by creating new work patterns from starting to Sampling - Production - Finishing.</p>
                            <p className="py-3">We also data to be a fashion consultant for you who are new and want to start a business in the field of fashion.</p>
                        </Fade>
                    </div>
                    <div className="col-md-6 col-12">
                        <Fade  delay={150}>
                            <p className="py-3">You don't need to confuse our data managing from the initial stage of your brand design to the production stage. And finally we can become a social media agency for your brand, from managing your social media to the toughest stage, our sales can handle it and we have an experienced team.</p>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
        <div  id="hero d-flex align-items-center" style={{ padding:'100px 0px' }}>
            <div className="container col-xxl-8 px-4">
                <div className="d-flex row flex-lg-row-reverse align-items-center g-5">
                    <div className="col-md-6 col-12">
                        <Fade left>
                        <img className="img-fluid" src="/assets/images/misc/about-2.png" alt="about-3" loading="lazy" width={'auto'} height={'auto'} />
                        </Fade>
                    </div>
                    <div className="col-md-6 col-12">
                        <Fade left>
                            <p className=" fw-600 section-tagline-title">The new way to be progress</p>
                        </Fade>
                        <Fade left  delay={150}>
                            <h2 className="display-5 lh-1 my-3 title fw-600">Experience keeps us developing and contributing</h2>
                        </Fade>
                        <Fade left delay={300} >
                            <p className="mt-4">
                            In developing the fashion industry in Indonesia. We have worked with several well-known local brands in Indonesia and more than 10 local brands. Quality, Service and Speed ​​are our values.
                            </p>
                            <p>
                            Here are some of our clients' portfolios.
                            </p>
                        </Fade>
                        <Fade left delay={600}>
                            <button className="btn btn-primary d-flex align-items-center">Portfolios<img className="ms-2" style={{width:'16px'}} src="/assets/images/icon/needle-flip.png" alt="about-4" loading="lazy" width={'auto'} height={'auto'} /></button>
                        </Fade>
                    </div>
                   
                    
                </div>
            </div>
        </div>
        
        </>
        

    )
}
export default Contents;