import React, { useEffect } from 'react'
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import FooterNew from '../../components/Layout/Footer/FooterNew';
import OurServices from '../../components/OurServices'
import SectionHead from './SectionHead';
import Products from '../../components/Products';
import Gallery from '../../components/Gallery';
import Clients from '../../components/Client';
import ContactUs from '../../components/ContactUs';
import Contents from './Contents';


const page={
    title: 'Portfolio - We stitch with passion to craft excellence.',
    description: 'Sewing.id is the right solution for fashion businesses on a small or large scale in terms of production. We cut the supply chain process by creating new work patterns from starting to Sampling - Production - FinishingTrust our clients brand'
}
const section={
    title: 'Portfolio',
    subtitle: 'We Turn Your Ideas Into Wonderfull Reality'
}


function Home() {
    useEffect(() => {
        document.title = page.title;
        document.description = page.description;
    }, []);
    return (
        <>
        <div style={{ width: '100vw',backgroundColor:'#fff', backgroundImage:'url("assets/images/background/bg-services.png")',backgroundSize:'cover',backgroundRepeat:'no-repeat'}}>
        <SectionHead section={section}/>
        <Contents/>
        <FooterNew/>
        <ToastContainer />
        </div>
        </>
    )
}

export default Home;
