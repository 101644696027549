import React from "react";
class Footer extends React.Component {
  render() {
    return(
      // <div className ="container footer-margin" style={{zIndex:'200 !important'}}>
      //   <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4">
      //     <div className="mb-3">
      //       <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none me-lg-auto">
      //           <img src={process.env.PUBLIC_URL +'/logo-dark.png'} alt="mdo" width="auto" height="42"/>
      //         </a>
      //     </div>
      //     <div className="w-100 row d-flex mb-4">
      //         <div className="col-lg-3 col-6">
      //           <ul className="ul-footer" style={{zIndex:'201 !important'}}>
      //             <li><a className="a-footer" href="#" style={{zIndex:'202 !important'}}> Stake</a></li>
      //             <li><a className="a-footer" href="#roadmap" >Roadmap</a></li>
      //             <li><a className="a-footer" href="#" >Developer</a></li>
      //             <li><a className="a-footer" href="#" >Explore</a></li>
      //           </ul>
      //         </div>
      //         <div className="col-lg-3 col-6">
      //           <ul className="ul-footer">
      //             <li><a className="a-footer" href="#" >Faucet</a></li>
      //             <li><a className="a-footer" href="#" >View Chart</a></li>
      //             <li><a className="a-footer" href="https://t.me/arvennetwork" target="_blank">Community</a></li>
      //             <li><a className="a-footer" href="https://arvennetwork.gitbook.io/docs/" target="_blank">Whitepaper</a></li>
      //           </ul>
      //         </div>
      //     </div>
      //     <div className="w-100 d-flex align-items-center justify-content-center ">
      //       <span className="mb-3 mb-md-0"> Copyright &copy; 2023 Arven Network</span>
      //     </div>
      //   </footer>
      // </div>
      <></>
    );
  }
}
export default Footer;