import React from "react";

function FooterNew(){
  return(
    <>
     <div className='section-footer'>
        <div className="container py-5">
            
            <div className="w-100 row d-flex mb-4">
                <div className="col-lg-6 col-12 mt-4">
                    <div className='d-flex flex-column'>
                        <img src={process.env.PUBLIC_URL +'/logo-light.png'} alt="logo" className="img-fluid mb-3"  loading="lazy" width={'auto'} height={'auto'} />
                    </div>
                </div>

                <div className="col-lg-3 col-6 mt-4">
                    <div className='d-flex flex-column'>
                        <h3 className='m-0 p-0 mb-2 footer-nav-title'>Contact Us</h3>
                        <p className="footer-nav-content-title">Get in touch with us!</p>
                        <h4 className="footer-nav-phone">(021) 2236 3483</h4>
                        <h4 className="footer-nav-phone">(+62) 852 1959 7077</h4>
                        <h4 className="footer-nav-phone">(+62) 812 1222 8990</h4>
                        <h4 className="footer-nav-phone">(+62) 821 2510 7378</h4>
                        <p className="footer-nav-content-title">noreply@sewing.id</p>
                    </div>
                </div>

                <div className="col-lg-3 col-6 mt-4">
                    <div className='d-flex flex-column'>
                    <h3 className='m-0 p-0 mb-2 footer-nav-title'>Location</h3>
                        <p className="footer-nav-content-title">Get in touch with us!</p>
                        <p className="footer-nav-content">Jl. Ketapang Utara No. 52F, RW. 7 Krukut, Kec. Taman Sari, Kota Jakarta Barat, Indonesia.</p>
                    </div>
                </div>
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start"></div>
        </div>
    </div>
    </>
  )
}

export default FooterNew;