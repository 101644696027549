import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fade from 'react-reveal/Fade';
import FooterNew from '../../../components/Layout/Footer/FooterNew';
import { portfolios } from '../../../data/Constats';


const page={
    title: 'Portfolio - We stitch with passion to craft excellence.',
    description: 'Sewing.id is the right solution for fashion businesses on a small or large scale in terms of production. We cut the supply chain process by creating new work patterns from starting to Sampling - Production - FinishingTrust our clients brand'
}

function Index({id}) {
    const [data, setData] = useState({});
    const [section, setSection] = useState({
        title: 'Portfolio',
        subtitle: 'We Turn Your Ideas Into Wonderfull Reality'
    });
    const params = useParams();
  
    useEffect(() => {
        const selectedData = portfolios[params.id];
        setData(selectedData);
        section.title = selectedData?.type;
        section.subtitle = selectedData?.name;
        setSection({...section});
        document.title = page.title;
        document.description = page.description;
    }, []);

    useEffect(() => {
        console.log(data);
        section.title = data?.type;
        section.subtitle = data?.name;
        setSection({...section});
    }, [data]);
    return (
        <>
        <div style={{ width: '100vw',backgroundColor:'#fff', backgroundImage:'url("/assets/images/background/bg-services.png")',backgroundSize:'cover',backgroundRepeat:'no-repeat'}}>
        <div  id="hero d-flex align-items-center" style={{paddingTop:'120px'}}>
            <div className="container col-xxl-8 px-4 py-4">
                <div> 
                    <span><a href="/portfolio" className='link-porto-detail'><i className='fa fa-chevron-left'></i> Portfolio </a>/ Detail</span>
                </div>
                
                <Fade top>
                    <p className=" fw-600 section-tagline-title mt-4">{section.title}</p>
                </Fade>
                <Fade top >
                    <h1 className="lh-1 fw-600 h2">{section.subtitle}</h1>
                </Fade>
                    
            
            </div>
        </div>
        <div className="container col-xxl-8 px-4 py-5">
            <div className="row align-items-center g-5">
                {data && data?.images?.length >0 &&
                    data.images.map((item, idx) => {
                        return (
                            <div className="col-lg-3 col-md-4 col-6 no-space" key={`index-${idx}`}>
                                <Fade bottom duration={100+Number(idx*5)}>
                                    <div className="img-hover-zoom w-100 h-100">
                                        <img className="img img-fluid w-100" src={item?.src} alt=""/>
                                    </div>
                                </Fade>
                            </div>
                        )
                    })
                }
                
                
            </div>
            <div className='text-center my-5'> 
                    <span><a href="/portfolio" className='link-porto-detail'><i className='fa fa-chevron-left'></i> Portfolio </a>/ Detail</span>
            </div>
        </div>
        <FooterNew/>
        <ToastContainer />
        </div>
        </>
    )

}

export default Index;
