import React from "react";
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Slider from 'react-infinite-logo-slider'
import ContactUs from "./ContactUs";
import { clients } from "../data/Constats";


const section={
    title: 'OUR CLIENT',
    description: 'Trust our clients brand'
}

const data = clients;

function ClientsMap(){
  return(
    <>
    
    <div className="service-section">
        <div className="container col-xxl-12 col-12 px-4 py-5">
            {/* <ContactUs/> */}
            <div className="w-100 mt-5">
                <Bounce right>
                <div className='text-center'/>
                    <h2 className=" fw-600 text-center text-label-title">{section.title}</h2>

                    <h3 className="display-5  text-center  lh-1 my-3 title fw-600">{section.description}</h3>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                </div>
                </Bounce>
            </div>
            
            <Slider
                width="150px"
                duration={40}
                pauseOnHover={false}
                blurBorders={false}
                blurBoderColor={'#fff'}
            >
               
               <Slider.Slide>
                    <img src={data[0].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[1].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[2].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[3].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[4].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[5].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[6].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[7].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[8].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[9].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[10].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[11].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[12].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[13].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[14].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[15].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[16].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
            </Slider>
        </div>
        
        <div>
       
        <div style={{width: '100%'}} className="mt-5"><iframe width="100%" height="250" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Jl.%20Ketapang%20Utara%20No.%2052F,%20RW.%207%20Krukut,%20Kec.%20Taman%20Sari,%20Kota%20Jakarta%20Barat,%20Indonesia+(Sewing.id)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div>
      
        </div>
    </div>
    </>
    
    
  )
}
export default ClientsMap;