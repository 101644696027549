import React from "react";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

const section={
    title: 'Portfolio',
    description: 'OUR LATEST WORKS'
}

const data = [
    {
        icon: 'assets/images/products/product-1.jpg',
        title:'100S OF YACHTS',
        description: 'Lorem ipsum proin gravida velit auctor alueut aenean sollicitu din, lorem auci elit consequat ipsutissem niuis sed odio sit amet a sit amet.'
    },
    {
        icon: 'assets/images/products/product-1.jpg',
        title:'PROFESSIONAL CREW',
        description: 'Lorem ipsum proin gravida velit auctor alueut aenean sollicitu din, lorem auci elit consequat ipsutissem niuis sed odio sit amet a sit amet.'
    },
    {
        icon: 'assets/images/products/product-1.jpg',
        title:'AMAZING PORTS',
        description: 'Lorem ipsum proin gravida velit auctor alueut aenean sollicitu din, lorem auci elit consequat ipsutissem niuis sed odio sit amet a sit amet.'
    },
    {
        icon: 'assets/images/products/product-1.jpg',
        title:'DISCOVER NEW PLACES',
        description: 'Lorem ipsum proin gravida velit auctor alueut aenean sollicitu din, lorem auci elit consequat ipsutissem niuis sed odio sit amet a sit amet.'
    },

]

function Gallery(){
  return(
    <div className="product-section">
        <div className="container col-xxl-12 col-12 px-4 py-5">
            <div className="row flex-lg-row align-items-center g-5 py-5">
                <div className="col-lg-12">
                    <Bounce right>
                    <div className='text-center'/>
                        <h2 className="title-sub fw-600 text-center">{section.title}</h2>
                        <p className='text-center subtitle-sub'>{section.description}</p>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                    </div>
                    </Bounce>
                </div>
                
            </div>
            <div className="d-flex row ">
                <div className="col-lg-6 col-12 no-space">
                    <div className="img-hover-zoom">
                        <img className="img img-fluid w-100" src="/assets/images/gallery/1.jpg" alt="sewing-galery-1" width={'auto'} height={'auto'} loading="lazy" />
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6 flex-column no-space">
                    <div className="img-hover-zoom">
                        <img className="img img-fluid w-100" src="/assets/images/gallery/2.jpg" alt="sewing-galery-1" width={'auto'} height={'auto'} loading="lazy" />
                    </div>
                    <div className="img-hover-zoom">
                        <img className="img img-fluid w-100" src="/assets/images/gallery/3.jpg" alt="sewing-galery-1" width={'auto'} height={'auto'} loading="lazy" />
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6 flex-column no-space">
                    <div className="img-hover-zoom">
                    <img className="img img-fluid w-100" src="/assets/images/gallery/4.jpg" alt="sewing-galery-1" width={'auto'} height={'auto'} loading="lazy" />
                    </div>
                    <div className="img-hover-zoom">
                    <img className="img img-fluid w-100" src="/assets/images/gallery/5.jpg" alt="sewing-galery-1" width={'auto'} height={'auto'} loading="lazy" />
                    </div>
                </div>
            </div>
            <div className="d-flex row">
                <div className="col-lg-3 col-md-6 col-6 flex-column no-space">
                    <div className="img-hover-zoom">
                        <img className="img img-fluid w-100" src="/assets/images/gallery/6.jpg" alt="sewing-galery-1" width={'auto'} height={'auto'} loading="lazy" />
                    </div>
                    <div className="img-hover-zoom">
                        <img className="img img-fluid w-100" src="/assets/images/gallery/7.jpg" alt="sewing-galery-1" width={'auto'} height={'auto'} loading="lazy" />
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6 flex-column no-space">
                    <div className="img-hover-zoom">
                    <img className="img img-fluid w-100" src="/assets/images/gallery/8.jpg" alt="sewing-galery-1" width={'auto'} height={'auto'} loading="lazy" />
                    </div>
                    <div className="img-hover-zoom">
                    <img className="img img-fluid w-100" src="/assets/images/gallery/9.jpg" alt="sewing-galery-1" width={'auto'} height={'auto'} loading="lazy" />
                    </div>
                </div>
                <div className="col-lg-6 col-12 no-space">
                    <div className="img-hover-zoom">
                        <img className="img img-fluid w-100" src="/assets/images/gallery/10.jpg" alt="sewing-galery-1" width={'auto'} height={'auto'} loading="lazy" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
export default Gallery;