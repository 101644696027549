import './styles/App.css';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/about/Index";
import Contact from "./pages/contact/Index";
import Services from "./pages/services/Index"
import Portfolio from "./pages/portfolio/Index";
import PortfolioDetail from "./pages/portfolio/detail/Index";

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio/:id" element={<PortfolioDetail />} />
      </Routes>
    </BrowserRouter>
  </>
  );
}

export default App;
