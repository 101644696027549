import React from "react";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';



function SectionHead({section}){
    return(
        <div  id="hero d-flex align-items-center" style={{paddingTop:'120px'}}>
            <div className="container col-xxl-8 px-4 py-4">
                <div className='text-center'/>
                    <Fade top>
                        <p className=" fw-600 section-tagline-title">{section.title}</p>
                    </Fade>
                    <Fade top >
                        <h1 className="display-5 lh-1 my-3 title fw-600">{section.subtitle}</h1>
                    </Fade>
                    
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                </div>
            </div>
        </div>
    )
}
export default SectionHead;