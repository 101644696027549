import React from "react";
import Bounce from 'react-reveal/Bounce';
import Slider from 'react-infinite-logo-slider'
import { clients } from "../data/Constats";


const section={
    title: 'OUR CLIENT',
    description: 'Trust our clients brand'
}

const data = clients;

function Clients(){
  return(
    <>
    
    <div className="service-section">
        <div className="container col-xxl-12 col-12 px-4 py-5">
            <div className="w-100">
                <Bounce right>
                <div className='text-center'/>
                    <h2 className=" fw-600 text-center text-label-title">{section.title}</h2>

                    <h3 className="display-5  text-center  lh-1 my-3 title fw-600">{section.description}</h3>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                </div>
                </Bounce>
            </div>
            <Slider
                width="150px"
                duration={40}
                pauseOnHover={false}
                blurBorders={false}
                blurBoderColor={'#fff'}
            >
               
               <Slider.Slide>
                    <img src={data[0].icon} className="icon-logo-partner" alt="icon-partner" loading="lazy" width={'auto'} height={'auto'}/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[1].icon} className="icon-logo-partner" alt="icon-partner" loading="lazy" width={'auto'} height={'auto'}/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[2].icon} className="icon-logo-partner" alt="icon-partner" loading="lazy" width={'auto'} height={'auto'}/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[3].icon} className="icon-logo-partner" alt="icon-partner" loading="lazy" width={'auto'} height={'auto'}/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[4].icon} className="icon-logo-partner" alt="icon-partner" loading="lazy" width={'auto'} height={'auto'}/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[5].icon} className="icon-logo-partner" alt="icon-partner" loading="lazy" width={'auto'} height={'auto'}/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[6].icon} className="icon-logo-partner" alt="icon-partner" loading="lazy" width={'auto'} height={'auto'}/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[7].icon} className="icon-logo-partner" alt="icon-partner" loading="lazy" width={'auto'} height={'auto'}/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[8].icon} className="icon-logo-partner" alt="icon-partner" loading="lazy" width={'auto'} height={'auto'}/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[9].icon} className="icon-logo-partner" alt="icon-partner" loading="lazy" width={'auto'} height={'auto'}/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[10].icon} className="icon-logo-partner" alt="icon-partner" loading="lazy" width={'auto'} height={'auto'}/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[11].icon} className="icon-logo-partner" alt="icon-partner" loading="lazy" width={'auto'} height={'auto'}/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[12].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[13].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[14].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[15].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
                <Slider.Slide>
                    <img src={data[16].icon} className="icon-logo-partner" loading="lazy" width={'auto'} height={'auto'} alt="partner"/>
                </Slider.Slide>
            </Slider>
            
        </div>
        
        <div>
            <img className="img img-fluid" style={{width:'100%',height:'auto'}} src="/assets/images/misc/after-client-2.png" alt="icon-partner" loading="lazy" width={'auto'} height={'auto'}></img>
        </div>
    </div>
    </>
    
    
  )
}
export default Clients;