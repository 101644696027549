import React, { useEffect } from 'react'
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FooterNew from '../../components/Layout/Footer/FooterNew';
import SectionHead from './SectionHead';
import Contents from './Contents';


const page={
    title: 'Services - Let`s make something great together',
    description: 'Sewing.id is the right solution for fashion businesses on a small or large scale in terms of production. We cut the supply chain process by creating new work patterns from starting to Sampling - Production - FinishingTrust our clients brand'
}
const section={
    title: 'Services',
    subtitle: 'Let`s make something great together'
}


function Home() {
    useEffect(() => {
        document.title = page.title;
        document.description = page.description;
    }, []);
    return (
        <>
        <div style={{ width: '100vw',backgroundColor:'#fff', backgroundImage:'url("assets/images/background/bg-services.png")',backgroundSize:'cover',backgroundRepeat:'no-repeat'}}>
        <SectionHead section={section}/>
        <Contents/>
        <FooterNew/>
        <ToastContainer />
        </div>
        </>
    )
}

export default Home;
