
import AbiToken from "../abi/AbiToken.json";
export const walletProvider = {
    METAMASK: 'metaMask',
    TRUSTWALLET: 'trustWallet',
    SAFEPAL: 'safePal',
    WALLET_CONNECT: 'walletConnect'
};


export const portfolios =[
    {
        name: 'Sejauh Mata Memandang',
        type: 'Brand',
        images: [
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/1.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/1.png',
                title:'sejauh-mata-memandang-1',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/2.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/2.png',
                title:'sejauh-mata-memandang-2',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/3.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/3.png',
                title:'sejauh-mata-memandang-3',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/4.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/4.png',
                title:'sejauh-mata-memandang-4',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/5.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/5.png',
                title:'sejauh-mata-memandang-5',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/6.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/6.png',
                title:'sejauh-mata-memandang-6',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/7.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/7.png',
                title:'sejauh-mata-memandang-7',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/8.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/8.png',
                title:'sejauh-mata-memandang-8',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/9.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/9.png',
                title:'sejauh-mata-memandang-9',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/10.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/10.png',
                title:'sejauh-mata-memandang-10',
                description: '-',
                link:'#'
            },

            {
                src: '/assets/images/gallery/sejauh-mata-memandang/11.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/11.png',
                title:'sejauh-mata-memandang-11',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/12.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/12.png',
                title:'sejauh-mata-memandang-12',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/13.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/13.png',
                title:'sejauh-mata-memandang-13',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/14.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/14.png',
                title:'sejauh-mata-memandang-14',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/15.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/15.png',
                title:'sejauh-mata-memandang-15',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/16.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/16.png',
                title:'sejauh-mata-memandang-16',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/17.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/17.png',
                title:'sejauh-mata-memandang-17',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/18.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/18.png',
                title:'sejauh-mata-memandang-18',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/19.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/19.png',
                title:'sejauh-mata-memandang-19',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/20.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/20.png',
                title:'sejauh-mata-memandang-20',
                description: '-',
                link:'#'
            },

            {
                src: '/assets/images/gallery/sejauh-mata-memandang/21.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/21.png',
                title:'sejauh-mata-memandang-21',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/22.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/22.png',
                title:'sejauh-mata-memandang-22',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/23.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/23.png',
                title:'sejauh-mata-memandang-23',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/24.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/24.png',
                title:'sejauh-mata-memandang-24',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/25.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/25.png',
                title:'sejauh-mata-memandang-25',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/26.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/26.png',
                title:'sejauh-mata-memandang-26',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/27.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/27.png',
                title:'sejauh-mata-memandang-27',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/28.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/28.png',
                title:'sejauh-mata-memandang-28',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/29.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/29.png',
                title:'sejauh-mata-memandang-29',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/30.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/30.png',
                title:'sejauh-mata-memandang-30',
                description: '-',
                link:'#'
            },

            {
                src: '/assets/images/gallery/sejauh-mata-memandang/31.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/31.png',
                title:'sejauh-mata-memandang-31',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/32.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/32.png',
                title:'sejauh-mata-memandang-32',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/33.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/33.png',
                title:'sejauh-mata-memandang-33',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/34.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/34.png',
                title:'sejauh-mata-memandang-34',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/35.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/35.png',
                title:'sejauh-mata-memandang-35',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/sejauh-mata-memandang/36.png',
                src_hi: '/assets/images/gallery/hi/sejauh-mata-memandang/36.png',
                title:'sejauh-mata-memandang-36',
                description: '-',
                link:'#'
            },
        ]
    },
    {
        name: 'Sapto Djojokartiko',
        type: 'Brand',
        images: [
            {
                src: '/assets/images/gallery/saptodjojokartiko/1.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/1.png',
                title:'saptodjojokartiko-1',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/2.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/2.png',
                title:'saptodjojokartiko-2',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/3.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/3.png',
                title:'saptodjojokartiko-3',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/4.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/4.png',
                title:'saptodjojokartiko-4',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/5.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/5.png',
                title:'saptodjojokartiko-5',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/6.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/6.png',
                title:'saptodjojokartiko-6',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/7.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/7.png',
                title:'saptodjojokartiko-7',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/8.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/8.png',
                title:'saptodjojokartiko-8',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/9.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/9.png',
                title:'saptodjojokartiko-9',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/10.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/10.png',
                title:'saptodjojokartiko-10',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/11.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/11.png',
                title:'saptodjojokartiko-11',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/12.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/12.png',
                title:'saptodjojokartiko-12',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/13.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/13.png',
                title:'saptodjojokartiko-13',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/14.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/14.png',
                title:'saptodjojokartiko-14',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/15.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/15.png',
                title:'saptodjojokartiko-15',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/16.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/16.png',
                title:'saptodjojokartiko-16',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/17.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/17.png',
                title:'saptodjojokartiko-17',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/18.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/18.png',
                title:'saptodjojokartiko-18',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/19.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/19.png',
                title:'saptodjojokartiko-19',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/20.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/20.png',
                title:'saptodjojokartiko-20',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/21.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/21.png',
                title:'saptodjojokartiko-21',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/22.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/22.png',
                title:'saptodjojokartiko-22',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/23.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/23.png',
                title:'saptodjojokartiko-23',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/24.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/24.png',
                title:'saptodjojokartiko-24',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/25.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/25.png',
                title:'saptodjojokartiko-25',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/26.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/26.png',
                title:'saptodjojokartiko-26',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/27.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/27.png',
                title:'saptodjojokartiko-27',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/saptodjojokartiko/28.png',
                src_hi: '/assets/images/gallery/hi/saptodjojokartiko/28.png',
                title:'saptodjojokartiko-28',
                description: '-',
                link:'#'
            },
        ]
    },
    {
        name: 'Votum Official by Sebastian Gunawan',
        type: 'Brand',
        images: [
            {
                src: '/assets/images/gallery/votum-official/1.png',
                src_hi: '/assets/images/gallery/hi/votum-official/1.png',
                title:'votum-official-1',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/2.png',
                src_hi: '/assets/images/gallery/hi/votum-official/2.png',
                title:'votum-official-2',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/3.png',
                src_hi: '/assets/images/galleryhi//votum-official/3.png',
                title:'votum-official-3',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/4.png',
                src_hi: '/assets/images/gallery/hi/votum-official/4.png',
                title:'votum-official-4',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/5.png',
                src_hi: '/assets/images/gallery/hi/votum-official/5.png',
                title:'votum-official-5',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/6.png',
                src_hi: '/assets/images/gallery/hi/votum-official/6.png',
                title:'votum-official-6',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/7.png',
                src_hi: '/assets/images/gallery/hi/votum-official/7.png',
                title:'votum-official-7',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/8.png',
                src_hi: '/assets/images/gallery/hi/votum-official/8.png',
                title:'votum-official-8',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/9.png',
                src_hi: '/assets/images/gallery/hi/votum-official/9.png',
                title:'votum-official-9',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/10.png',
                src_hi: '/assets/images/gallery/hi/votum-official/10.png',
                title:'votum-official-10',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/11.png',
                src_hi: '/assets/images/gallery/hi/votum-official/11.png',
                title:'votum-official-11',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/12.png',
                src_hi: '/assets/images/gallery/hi/votum-official/12.png',
                title:'votum-official-12',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/13.png',
                src_hi: '/assets/images/gallery/hi/votum-official/13.png',
                title:'votum-official-13',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/14.png',
                src_hi: '/assets/images/gallery/hi/votum-official/14.png',
                title:'votum-official-14',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/15.png',
                src_hi: '/assets/images/gallery/hi/votum-official/15.png',
                title:'votum-official-15',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/16.png',
                src_hi: '/assets/images/gallery/hi/votum-official/16.png',
                title:'votum-official-16',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/17.png',
                src_hi: '/assets/images/gallery/hi/votum-official/17.png',
                title:'votum-official-17',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/18.png',
                src_hi: '/assets/images/gallery/hi/votum-official/18.png',
                title:'votum-official-18',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/19.png',
                src_hi: '/assets/images/gallery/hi/votum-official/19.png',
                title:'votum-official-19',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/20.png',
                src_hi: '/assets/images/gallery/hi/votum-official/20.png',
                title:'votum-official-20',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/21.png',
                src_hi: '/assets/images/gallery/hi/votum-official/21.png',
                title:'votum-official-21',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/22.png',
                src_hi: '/assets/images/gallery/hi/votum-official/22.png',
                title:'votum-official-22',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/23.png',
                src_hi: '/assets/images/gallery/hi/votum-official/23.png',
                title:'votum-official-23',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/votum-official/24.png',
                src_hi: '/assets/images/gallery/hi/votum-official/24.png',
                title:'votum-official-24',
                description: '-',
                link:'#'
            },
        ]
    },
    {
        name: 'Ayu by Ayu Lestari Official',
        type: 'Brand',
        images: [
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/1.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/1.png',
                title:'ayubyayulestariofficial-1',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/2.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/2.png',
                title:'ayubyayulestariofficial-2',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/3.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/3.png',
                title:'ayubyayulestariofficial-3',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/4.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/4.png',
                title:'ayubyayulestariofficial-4',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/5.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/5.png',
                title:'ayubyayulestariofficial-5',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/6.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/6.png',
                title:'ayubyayulestariofficial-6',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/7.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/7.png',
                title:'ayubyayulestariofficial-7',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/8.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/8.png',
                title:'ayubyayulestariofficial-8',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/9.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/9.png',
                title:'ayubyayulestariofficial-9',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/10.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/10.png',
                title:'ayubyayulestariofficial-10',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/11.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/11.png',
                title:'ayubyayulestariofficial-11',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/12.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/12.png',
                title:'ayubyayulestariofficial-12',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/13.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/13.png',
                title:'ayubyayulestariofficial-13',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/14.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/14.png',
                title:'ayubyayulestariofficial-14',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/15.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/15.png',
                title:'ayubyayulestariofficial-15',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/16.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/16.png',
                title:'ayubyayulestariofficial-16',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/17.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/17.png',
                title:'ayubyayulestariofficial-17',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/18.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/18.png',
                title:'ayubyayulestariofficial-18',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/19.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/19.png',
                title:'ayubyayulestariofficial-19',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/20.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/20.png',
                title:'ayubyayulestariofficial-20',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/21.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/21.png',
                title:'ayubyayulestariofficial-21',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/22.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/22.png',
                title:'ayubyayulestariofficial-22',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/23.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/23.png',
                title:'ayubyayulestariofficial-23',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/ayubyayulestariofficial/24.png',
                src_hi: '/assets/images/gallery/hi/ayubyayulestariofficial/24.png',
                title:'ayubyayulestariofficial-24',
                description: '-',
                link:'#'
            },
        ]
    },
    {
        name: 'Vone Official',
        type: 'Brand',
        images: [
            {
                src: '/assets/images/gallery/vone-official/1.png',
                src_hi: '/assets/images/gallery/hi/vone-official/1.png',
                title:'vone-official-1',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/vone-official/2.png',
                src_hi: '/assets/images/gallery/hi/vone-official/2.png',
                title:'vone-official-2',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/vone-official/3.png',
                src_hi: '/assets/images/gallery/hi/vone-official/3.png',
                title:'vone-official-3',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/vone-official/4.png',
                src_hi: '/assets/images/gallery/hi/vone-official/4.png',
                title:'vone-official-4',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/vone-official/5.png',
                src_hi: '/assets/images/gallery/hi/vone-official/5.png',
                title:'vone-official-5',
                description: '-',
                link:'#'
            },
        ]
    },
    {
        name: 'Donna Prive',
        type: 'Brand',
        images: [
            {
                src: '/assets/images/gallery/donaprive/1.png',
                src_hi: '/assets/images/gallery/hi/donaprive/1.png',
                title:'donaprive-1',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/donaprive/2.png',
                src_hi: '/assets/images/gallery/hi/donaprive/2.png',
                title:'donaprive-2',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/donaprive/3.png',
                src_hi: '/assets/images/gallery/hi/donaprive/3.png',
                title:'donaprive-3',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/donaprive/4.png',
                src_hi: '/assets/images/gallery/hi/donaprive/4.png',
                title:'donaprive-4',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/donaprive/5.png',
                src_hi: '/assets/images/gallery/hi/donaprive/5.png',
                title:'donaprive-5',
                description: '-',
                link:'#'
            },
        ]
    },
    {
        name: 'Cottonink',
        type: 'Brand',
        images: [
            {
                src: '/assets/images/gallery/cottonink/1.png',
                src_hi: '/assets/images/gallery/hi/cottonink/1.png',
                title:'cottonink-1',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/cottonink/2.png',
                src_hi: '/assets/images/gallery/hi/cottonink/2.png',
                title:'cottonink-2',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/cottonink/3.png',
                src_hi: '/assets/images/gallery/hi/cottonink/3.png',
                title:'cottonink-3',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/cottonink/4.png',
                src_hi: '/assets/images/gallery/hi/cottonink/4.png',
                title:'cottonink-4',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/cottonink/5.png',
                src_hi: '/assets/images/gallery/hi/cottonink/5.png',
                title:'cottonink-5',
                description: '-',
                link:'#'
            },
        ]
    },
    {
        name: '3 Mongkis',
        type: 'Brand',
        images: [
            {
                src: '/assets/images/gallery/3mongkis/1.png',
                src_hi: '/assets/images/gallery/hi/3mongkis/1.png',
                title:'3mongkis-1',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/3mongkis/2.png',
                src_hi: '/assets/images/gallery/hi/3mongkis/2.png',
                title:'3mongkis-2',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/3mongkis/3.png',
                src_hi: '/assets/images/gallery/hi/3mongkis/3.png',
                title:'3mongkis-3',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/3mongkis/4.png',
                src_hi: '/assets/images/gallery/hi/3mongkis/4.png',
                title:'3mongkis-4',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/3mongkis/5.png',
                src_hi: '/assets/images/gallery/hi/3mongkis/5.png',
                title:'3mongkis-5',
                description: '-',
                link:'#'
            },
        ]
    },
    {
        name: 'Posh the Label',
        type: 'Brand',
        images: [
            {
                src: '/assets/images/gallery/poshthelabel/1.png',
                src_hi: '/assets/images/gallery/hi/poshthelabel/1.png',
                title:'poshthelabel-1',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/poshthelabel/2.png',
                src_hi: '/assets/images/gallery/hi/poshthelabel/2.png',
                title:'poshthelabel-2',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/poshthelabel/3.png',
                src_hi: '/assets/images/gallery/hi/poshthelabel/3.png',
                title:'poshthelabel-3',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/poshthelabel/4.png',
                src_hi: '/assets/images/gallery/hi/poshthelabel/4.png',
                title:'poshthelabel-4',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/poshthelabel/5.png',
                src_hi: '/assets/images/gallery/hi/poshthelabel/5.png',
                title:'poshthelabel-5',
                description: '-',
                link:'#'
            },
        ]
    },
  
    {
        name: 'Stellarissa',
        type: 'Brand',
        images: [
            {
                src: '/assets/images/gallery/stellarissa/1.png',
                src_hi: '/assets/images/gallery/hi/stellarissa/1.png',
                title:'stellarissa-1',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/stellarissa/2.png',
                src_hi: '/assets/images/gallery/hi/stellarissa/2.png',
                title:'stellarissa-2',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/stellarissa/3.png',
                src_hi: '/assets/images/gallery/hi/stellarissa/3.png',
                title:'stellarissa-3',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/stellarissa/4.png',
                src_hi: '/assets/images/gallery/hi/stellarissa/4.png',
                title:'stellarissa-4',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/stellarissa/5.png',
                src_hi: '/assets/images/gallery/hi/stellarissa/5.png',
                title:'stellarissa-5',
                description: '-',
                link:'#'
            },
        ]
    },
    {
        name: 'Fox Quinn',
        type: 'Brand',
        images: [
            {
                src: '/assets/images/gallery/foxquinn/1.png',
                src_hi: '/assets/images/gallery/hi/foxquinn/1.png',
                title:'foxquinn-1',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/foxquinn/2.png',
                src_hi: '/assets/images/gallery/hi/foxquinn/2.png',
                title:'foxquinn-2',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/foxquinn/3.png',
                src_hi: '/assets/images/gallery/hi/foxquinn/3.png',
                title:'foxquinn-3',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/foxquinn/4.png',
                src_hi: '/assets/images/gallery/hi/foxquinn/4.png',
                title:'foxquinn-4',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/foxquinn/5.png',
                src_hi: '/assets/images/gallery/hi/foxquinn/5.png',
                title:'foxquinn-5',
                description: '-',
                link:'#'
            },
        ]
    },
    {
        name: 'Chocochips Boutique',
        type: 'Brand',
        images: [
            {
                src: '/assets/images/gallery/chocochips/1.png',
                src_hi: '/assets/images/gallery/hi/chocochips/1.png',
                title:'chocochips-1',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/chocochips/2.png',
                src_hi: '/assets/images/gallery/hi/chocochips/2.png',
                title:'chocochips-2',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/chocochips/3.png',
                src_hi: '/assets/images/gallery/hi/chocochips/3.png',
                title:'chocochips-3',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/chocochips/4.png',
                src_hi: '/assets/images/gallery/hi/chocochips/4.png',
                title:'chocochips-4',
                description: '-',
                link:'#'
            },
            {
                src: '/assets/images/gallery/chocochips/5.png',
                src_hi: '/assets/images/galleryhi/chocochips/5.png',
                title:'chocochips-5',
                description: '-',
                link:'#'
            },
        ]
    }
]


export const clients =  [
    {
        icon: '/assets/images/clients/sewing-mitsubishi.png',
        title:'Mitsubishi',
        link: '#'
    },
    {
        icon: '/assets/images/clients/sewing-mazda.png',
        title:'Mazda',
        link: '#'
    },
    {
        icon: '/assets/images/clients/sewing-pertamina.png',
        title:'Pertamina',
        link: '#'
    },
    {
        icon: '/assets/images/clients/sewing-holiwing.png',
        title:'Client A',
        link: '#'
    },
    {
        icon: '/assets/images/clients/sewing-detol.png',
        title:'Client A',
        link: '#'
    },
    {
        icon: '/assets/images/clients/sewing-barbie.png',
        title:'Client A',
        link: '#'
    },
    {
        icon: '/assets/images/clients/sewing-conch.png',
        title:'Client A',
        link: '#'
    },
    
    {
        icon: '/assets/images/clients/sewing-shopee.png',
        title:'Client A',
        link: '#'
    },
    {
        icon: '/assets/images/clients/sewing-jnt.png',
        title:'Client A',
        link: '#'
    },
    {
        icon: '/assets/images/clients/sewing-sicepat.png',
        title:'Client A',
        link: '#'
    },
    {
        icon: '/assets/images/clients/sewing-cottonink.png',
        title:'Client A',
        link: '#'
    },
    {
        icon: '/assets/images/clients/sewing-3mongkis.png',
        title:'Client A',
        link: '#'
    },
    {
        icon: '/assets/images/clients/sewing-donna.png',
        title:'Client A',
        link: '#'
    },
    {
        icon: '/assets/images/clients/sewing-sj.png',
        title:'Client A',
        link: '#'
    },
    {
        icon: '/assets/images/clients/sewing-sg.png',
        title:'Client A',
        link: '#'
    },
    {
        icon: '/assets/images/clients/sewing-stellarissa.png',
        title:'Client A',
        link: '#'
    },
    {
        icon: '/assets/images/clients/sewing-posh.png',
        title:'Client A',
        link: '#'
    },
    


]


//TESTNET
export const RPC_NODE_TESTNET={
    http:"https://apis.ankr.com/1d773c13580146fb9c5419ee763d2543/9deca392b81a904bd9568c58d3f47228/binance/full/test",
    wss:"wss://apis.ankr.com/wss/1d773c13580146fb9c5419ee763d2543/9deca392b81a904bd9568c58d3f47228/binance/full/test",
}

export const RPC_NODE={
    http:"https://apis.ankr.com/b2a3cdabe89d478f8f41e738832c92fb/9deca392b81a904bd9568c58d3f47228/binance/full/main",
    wss:"wss://apis.ankr.com/wss/b2a3cdabe89d478f8f41e738832c92fb/9deca392b81a904bd9568c58d3f47228/binance/full/main"
}

export const REF_FAUCET={
   address:'0x0000000000000000000000000000000000000000'
}
